<div class="company-card">
  <img
    [alt]="companyName"
    [src]="company.logoUrl || 'assets/svg/common/new-company-placeholder.svg'"
    class="company-card__logo"
    height="88px"
    width="88px"
  />

  <div class="company-card__content">
    <div class="company-card__content-name">
      {{ companyName }}
    </div>

    <div class="company-card__content-industries">
      @if (companyIndustries.length > 0) {
        @for (industry of companyIndustries; track industry) {
          <cc-chip size="small">
            {{ industry }}
          </cc-chip>
        }
        @if (industryCount > 3) {
          <span class="company-card__content-industries-more"> +{{ industryCount - 3 }} more </span>
        }
      }
    </div>

    <div class="company-card__content-location">
      {{ formattedLocation || 'common.strings.noLocationProvided' | translate }}
    </div>
  </div>
</div>
